<template>
  <div class="pdf-container">
    <div class="mt-10" style="text-align: center;">
      <span class="page-heading">Campaign Banner Details</span>
      <br><br>
    </div>
    <vue-pdf-app theme="light" :pdf=pdfUrl :config="config" page-scale="page-width"></vue-pdf-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfUrl: null,
      title: null,
      config: {
        secondaryToolbar: {
          documentProperties: false,
        },
        toolbar: {
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
          },
        }
      },
    }
  },
  mounted() {
    this.$gtag.event("landed on campaign banner details page", {
      'event_category': "Dashboard Page"
    });

    this.$feedback.showLoading();

    let requestBody = {};
    requestBody.bannerUid = this.$route.query.id;

    this.$cppClient.post('/dynamic-banner/get', requestBody)
        .then(({data}) => {
          this.pdfUrl = data.bannerPdfUrl;
          this.title = data.title;
        })
        .catch(({response}) => {
            this.$feedback.showFailed(response);
        })
        .finally(() => {
          this.$feedback.hideLoading();
        })
  }
}
</script>

<style lang="scss" scoped>
.pdf-container {
  height: 100vh;
  margin-top: 135px;
}

#outerContainer {
  z-index: 0 !important;
}

.pdf-app.light {
  --pdf-toolbar-color: white;
  --pdf-toolbar-font-color: grey;
  --pdf-input-color: grey;
}

::v-deep .toolbar {
  z-index: 150 !important;
}
</style>
